import * as ng from 'angular';
import {
    EditFormMessage,
    EditFormSavingStatus,
    EditFormStatus,
    EditPanelStatus,
    MoleculeFormEditController,
    PanelType
} from '@/atomic-components/molecules/forms';

type Validator = () => true|unknown;

// eslint-disable-next-line no-shadow
export enum DeletePanelButtonType {
    DELETE,
    NEXT_STEP
}

export class MoleculePanelDeleteController implements ng.IController {
    public static $inject: string[] = ['$transclude', '$translate'];

    // Bindings
    public agreements = false;
    public deletePanelButtonType: DeletePanelButtonType = DeletePanelButtonType.DELETE;
    public cancelDeleteButtonText: string;
    public changesAffectPayment: boolean;
    public deleteButtonText: string;
    public initialStatus: EditPanelStatus;
    public name: string;
    public panelDisabled = false;
    public panelIcon: string;
    public readonly type = PanelType.DELETE;

    // Required parent controller
    public $editForm: MoleculeFormEditController;

    public $validators: { [key: string]: Validator } = {};

    private _panelTitle: string;

    private status: EditPanelStatus = EditPanelStatus.READONLY;

    constructor(
        private $transclude: ng.ITranscludeFunction,
        private $translate: ng.translate.ITranslateService
    ) {}

    public cancelCallback: () => void = () => { /* linter fix */ };

    public $onInit = (): void => {
        this.cancelDeleteButtonText = this.$translate.instant(
            (
                this.changesAffectPayment
                    ? /* translationId */ '8aeea36b-2cb6-47f1-83d3-e652a68d74f6'
                    : /* translationId */ 'TR_230119-3f6dbf_TR'
            )
        );

        this.deleteButtonText = this.changesAffectPayment
            ? this.$translate.instant('GENERAL.ACTION.TERMINATE')
            : this.$translate.instant('GENERAL.FORM.DELETE');

        this.$editForm.register(this);

        if ([undefined, null].indexOf(this.initialStatus) < 0) {
            this.status = this.initialStatus;
        }
    };

    public $onDestroy = (): void => {
        this.$editForm.unregister(this);
    };

    public onMessage = (message: EditFormMessage<EditFormSavingStatus>): void => {
        switch (message.type) {
            default: return;

            case 'statusUpdate':
                switch (message.message) {
                    default: return;

                    case EditFormSavingStatus.SAVING:
                        // Currently doesn't have an effect here,
                        // all logic using "saving" status already uses the form's status.
                        return;

                    case EditFormSavingStatus.ERROR:
                        // TODO: Use logic. Error status if there is an error in this box, editable otherwise.
                        if (this.status !== EditPanelStatus.READONLY) {
                            this.status = EditPanelStatus.ACTIVE;
                        }
                        return;

                    case EditFormSavingStatus.READY:
                        // After successfully saving, return all panels to readonly status.
                        this.status = EditPanelStatus.READONLY;
                        return;
                }
        }
    };

    public get isButtonTypeDelete(): boolean {
        return this.deletePanelButtonType === DeletePanelButtonType.DELETE;
    }

    public get isButtonTypeNextStep(): boolean {
        return this.deletePanelButtonType === DeletePanelButtonType.NEXT_STEP;
    }

    public deleteButtonActive(): boolean {
        return this.agreements;
    }

    public get panelTitle(): string {
        if ([undefined, null, ''].indexOf(this._panelTitle) < 0) {
            return this._panelTitle;
        }

        if (this.showCancelDeletion) {
            return this.$translate.instant('TR_140119-33716a_TR');
        }

        return this.changesAffectPayment
            ? this.$translate.instant('32420761-b4c3-4d5f-8450-985d2dd7e43f')
            : this.$translate.instant('TR_140119-aee71e_TR');
    }

    /**
     * Returns true if all input elements within the panel are free of errors,
     * false otherwise.
     * Warning: potentially slow, because it has to check ALL input fields!
     */
    public get $valid(): boolean {
        return Object.keys(this.$validators)
        .every((key) => this.$validators[key]() === true);
    }

    /**
     * Returns true if some input element within the panel has an error,
     * false otherwise.
     * Faster than $valid if there are errors, because the check is finished as soon as
     * the first error is found.
     */
    public get $invalid(): boolean {
        return Object.keys(this.$validators)
        .some((key) => this.$validators[key]() !== true);
    }

    /**
     * Returns an object containing all errors found within the panel.
     * Always an object, but it may be empty if no errors are found.
     */
    public get $errors(): unknown {
        const errors = {};
        for (const key of Object.keys(this.$validators)) {
            const result = this.$validators[key]();
            if (result !== true) {
                errors[key] = result;
            }
        }
        return errors;
    }

    public activate = (): void => {
        this.status = EditPanelStatus.ACTIVE;
    };

    public delete = (): void => {
        if (this.$editForm.$invalid) {
            return;
        }

        void this.$editForm.delete();
    };

    public cancel = (): void => {
        this.status = EditPanelStatus.READONLY;
        if ([undefined, null].indexOf(this.cancelCallback) === -1) {
            this.cancelCallback();
        }
    };

    public cancelDeletion = (): void  => {
        this.status = EditPanelStatus.READONLY;
        void this.$editForm.cancelDeletion();
    };

    public successfullySaved = (): void => {
        this.status = EditPanelStatus.READONLY;
    };

    public errorWhileSaving = (): void => {
        this.status = EditPanelStatus.ACTIVE;
    };

    public get isEditable(): boolean {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showCancelButton(): boolean {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showEditButton(): boolean {
        return [
            EditPanelStatus.READONLY
        ].indexOf(this.status) >= 0
        && this.isButtonTypeDelete
        && !this.panelDisabled
        && !this.isButtonTypeNextStep;
    }

    public get showNextStepButton(): boolean {
        return [
            EditPanelStatus.READONLY
        ].indexOf(this.status) >= 0
        && this.isButtonTypeNextStep
        && !this.$editForm.processApiCallbackSent;
    }

    public get showSaveButton(): boolean {
        if (EditPanelStatus.DELETIONSCHEDULED === this.status) {
            return false;
        }
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0
        && !this.panelDisabled;
    }

    public get showCancelDeletion(): boolean {
        return [
            EditPanelStatus.DELETIONSCHEDULED
        ].indexOf(this.status) >= 0;
    }

    public get showEditableContent(): boolean {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showReadonlyContent(): boolean {
        return [
            EditPanelStatus.READONLY
        ].indexOf(this.status) >= 0;
    }

    public get showErrorContent(): boolean {
        return [
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showSavingContent(): boolean {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get saveButtonLoading(): boolean {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get disableCancelButton(): boolean {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get disableEditButton(): boolean {
        return !this.$editForm.canEnablePanel(this.name);
    }

    public get disableSaveButton(): boolean {
        return this.$editForm.$invalid
        || (this.$editForm.$status === EditFormStatus.SAVING)
        || (this.agreements !== true);
    }

    public get disableInputElements(): boolean {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get readonlyTranscludeFilled(): boolean {
        return this.$transclude.isSlotFilled('readonly');
    }

    public get editableTranscludeFilled(): boolean {
        return this.$transclude.isSlotFilled('editable');
    }

    public get savingTranscludeFilled(): boolean {
        return this.$transclude.isSlotFilled('saving');
    }

    public get errorTranscludeFilled(): boolean {
        return this.$transclude.isSlotFilled('error');
    }

    public get cancelDeletionTranscludeFilled(): boolean {
        return this.$transclude.isSlotFilled('cancelDeletion');
    }
}

export class MoleculePanelDeleteComponent implements ng.IComponentOptions {
    public bindings = {
        _panelTitle: '@?title',
        agreements: '<',
        deletePanelButtonType: '<?',
        cancelCallback: '<cancel',
        changesAffectPayment: '<paid',
        initialStatus: '<',
        name: '@',
        panelDisabled: '<',
        panelIcon: '@icon',
        panelRight: '<'
    };

    public require = {
        $editForm: '^moleculeFormEdit'
    };

    public transclude = {
        cancelDeletion: '?panelContentCancelDeletion',
        editable: 'panelContentEditable',
        error: '?panelContentError',
        readonly: '?panelContentReadonly',
        saving: '?panelContentSaving'
    };

    public controller =  MoleculePanelDeleteController;
    public controllerAs = '$deletePanel';
    public template = require('./panel-delete.html');
}
