/* eslint-disable */

import * as ng from 'angular';
import { UiRights } from '../../../../../../configuration';
import { OrganismDashboardOverviewsController } from '../../../../../organisms/overview';
import { LinkListItem } from '../../../../links';
import {  AuthContextService, } from '@/services';
import * as Types from '@/types';
export class MoleculeOverviewRowPanelDashboardMachinesController implements ng.IController {

    public get showLinkToOverview() {
        return this.serviceItemList.length > 0;
    }

    public get showAccounts() {
        return this.OrganismDashboardOverview.globals.showAccounts;
    }
    public static $inject: string[] = ['$state', '$translate', 'productsModel'];

    public serviceItemList: any[];
    public OrganismDashboardOverview: OrganismDashboardOverviewsController;
    public showObjectId: boolean;
    public machineProducts: Types.ProductApi.AbstractProduct[];

    private actionLinkLists: { [key: string]: LinkListItem[] } = {};

    constructor(
        private $state: ng.ui.IStateService,
        protected $translate: ng.translate.ITranslateService,
    ) {}

    public actionLinkList: (item: any) => LinkListItem[] = (item) => {
        if (!this.actionLinkLists[item.id]) {
            this.actionLinkLists[item.id] = this.terminalAllowed(item)
            ? [
                {
                    onclickMethode: this.openTerminal(item),
                    text: this.$translate.instant('TR_100119-5c3169_TR')
                },
                {
                    route: 'machine.virtualmachines.id.edit',
                    routeParams: { machineId: item.id },
                    text: this.$translate.instant('TR_100119-0e02c2_TR')
                },
                {
                    route: 'machine.virtualmachines.id.edit',
                    routeParams: { machineId: item.id },
                    text: this.$translate.instant('TR_100119-fa918b_TR')
                }
            ]
            : [];
        }

        return this.actionLinkLists[item.id];
    };

    public isSimpleVM = (item: any) => {
        return item.managementType === 'none'
    }

    public noMachinesOnDashboard = () => {
        return this.serviceItemList.length === 0;
    };

    /* Used for machine info panel */
    private terminalAllowed = (item: any) => {
        const machine = [undefined, null].indexOf(item.power) < 0 ? item : undefined;
        if (!machine) {
            return false;
        }

        return machine.power === 'on'
            && machine.status === 'active'
            && (
                machine.managementType === 'none'
                || AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN)
            );
    };

    /* Used for machine info panel */
    private openTerminal = (item: any) => ($event: any) => {
        const machine = [undefined, null].indexOf(item.power) < 0 ? item : undefined;
        if (!machine) {
            return false;
        }

        if ($event) {
            $event.stopPropagation();
        }

        window.open(
            this.$state.href('machine.virtualmachines.id.vnc', { machineId:machine.id }),
            `terminal_vm_${machine.id}`,
            'menubar=no,location=yes,resizable=no,scrollbars=no,status=yes,width=1024,height=928'
        );
    };
}

export class MoleculeOverviewRowPanelDashboardMachinesComponent implements ng.IComponentOptions {
    public require = {
        OrganismDashboardOverview: '^organismDashboardOverviews'
    };
    public bindings = {
        serviceItemList: '<',
        showObjectId: '<'
    };
    public controller =  MoleculeOverviewRowPanelDashboardMachinesController;
    public controllerAs = 'DashboardOverviewCtrl';
    public template = require('./dashboard-machines.html');
}
