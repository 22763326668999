/* eslint-disable */

import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels';
import { SHORT_TIMEOUT_REFRESH_INTERVAL, UiRights } from '../../../configuration';
import { AuthContextService, MachineJobModelService, MachineModelService } from '../../../services';
import * as Types from '../../../types';
import { DEFAULT_TIMEOUT_REFRESH_INTERVAL } from './../../../configuration/system';

export class TemplateMachineEditController {
    public static $inject: string[] = ['$timeout', '$translate', 'localInterval', 'machineJobModel', 'machineModel'];

    public checkingMachineStatus = false;
    public checkingForJobsInterval: ng.IPromise<any>;
    public faqArticleIdList: string[] = [
        'machine-managed-vs-cloud'
    ];
    public hadJobsOnPageLoad = false;
    public machine: Types.MachineApi.VirtualMachine;
    public machineHasUnfinishedJob = false;
    public machineStatusRefreshed = false;
    public machineStatusSupport = false;
    public modifier = '';
    public pageHeaderData: PanelHeaderData;
    public showObjectId: boolean;
    public userPanelRight;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private localInterval: ng.IIntervalService,
        private machineJobModel: MachineJobModelService,
        private machineModel: MachineModelService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'machine.dashboard',
            backwardText: this.$translate.instant('TR_140119-da3ae1_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: 'cloud',
            panelTitle: [undefined, null].indexOf(this.machine) >= 0
                ? this.$translate.instant('TR_090119-cc9d25_TR')
                : this.machine.name
        };

        const deleteRightsGranted: boolean = [undefined, null, 'none', ''].indexOf(this.machine?.managementType) >= 0
            ? AuthContextService.isGranted(UiRights.MACHINE_VM_DELETE)
            : AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN);

        const editRightsGranted: boolean = [undefined, null, 'none', ''].indexOf(this.machine?.managementType) >= 0
                ? AuthContextService.isGranted(UiRights.MACHINE_VM_EDIT)
                : AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN);

        this.userPanelRight = {
            deletion: {
                delete: deleteRightsGranted,
                deletePermanently: deleteRightsGranted,
                editPanelButton: deleteRightsGranted
            },
            general: {
                editPanelButton: editRightsGranted
            },
            restore: {
                deletePermanently: deleteRightsGranted,
                editPanelButton: editRightsGranted
            }
        };

        if (
            AuthContextService.isGranted(UiRights.MACHINE_VM_CHANGE_PRODUCT)
            && (
                [undefined, null, 'none', ''].indexOf(this.machine?.managementType) >= 0
                || AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN)
            )
        ) {
            /** For product changes:
             *  MACHINE_VM_CHANGE_PRODUCT is required regardless of management type.
             *  ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN is required ON TOP of that for managed servers.
             **/
            this.pageHeaderData.productActions = [
                {
                    route: 'machine.virtualmachines.id.upgrade',
                    stateParams: { machineId: this.machine.id },
                    title: this.$translate.instant('TR_140119-f50be5_TR')
                }
            ];
        }

        this.machineJobModel.findRunningJobsForMachine(this.machine)
        .then((innerJobResult) => {
            this.machineHasUnfinishedJob = innerJobResult.status === 'success'
                && innerJobResult.response.data.length > 0;

            if (this.machineHasUnfinishedJob === false) {
                this.machineModel.findOneById(this.machine.id).then((machineUpdated) => {
                    this.machine = machineUpdated;
                });
            } else {
                this.updateMachineStatus();
            }
        });
    };

    public updateMachineStatus = () => {
        if ([undefined, null].indexOf(this.checkingForJobsInterval) < 0) {
            this.localInterval.cancel(this.checkingForJobsInterval);
            this.checkingForJobsInterval = null;
        }

        this.checkingMachineStatus = true;
        this.machineJobModel.findRunningJobsForMachine(this.machine)
            .then((outerJobResult) => {
            this.machineHasUnfinishedJob = outerJobResult.status === 'success'
                && outerJobResult.response.data.length > 0;
            this.checkingForJobsInterval = this.localInterval(() => {
                this.machineJobModel.findRunningJobsForMachine(this.machine)
                .then((innerJobResult) => {
                    this.machineHasUnfinishedJob = innerJobResult.status === 'success'
                        && innerJobResult.response.data.length > 0;
                    if ((innerJobResult.response.data as any[]).some((job) => job.status === 'support')) {
                        if ([undefined, null].indexOf(this.checkingForJobsInterval) < 0) {
                            this.localInterval.cancel(this.checkingForJobsInterval);
                            this.checkingForJobsInterval = null;
                        }
                        this.checkingMachineStatus = false;
                        this.machineStatusRefreshed = false;
                        this.machineStatusSupport = true;
                    } else {
                        this.machineStatusSupport = false;
                    }
                    if (this.machineHasUnfinishedJob === false) {
                        this.machineModel.findOneById(this.machine.id).then((machineUpdated) => {
                            this.machine = machineUpdated;
                            this.pageHeaderData.panelTitle = machineUpdated.name;
                            if ([undefined, null].indexOf(this.checkingForJobsInterval) < 0) {
                                this.localInterval.cancel(this.checkingForJobsInterval);
                                this.checkingForJobsInterval = null;
                                this.$timeout(() => {
                                    this.checkingMachineStatus = false;
                                    this.machineStatusRefreshed = true;
                                    },
                                    SHORT_TIMEOUT_REFRESH_INTERVAL);
                                this.$timeout(() => this.machineStatusRefreshed = false,
                                    DEFAULT_TIMEOUT_REFRESH_INTERVAL);
                            }
                        });
                    }
                });
            },
            SHORT_TIMEOUT_REFRESH_INTERVAL);
        });
    };

    public cancelMachineChanges = async () => {
        try {
            if ([undefined, null].indexOf(this.machine) < 0) {
                this.machine = await this.machineModel.findOneById(this.machine.id);
            }
        } catch (error) {
            this.$timeout(this.cancelMachineChanges, SHORT_TIMEOUT_REFRESH_INTERVAL);
        }
    };

    public $onDestroy() {
        if ([undefined, null].indexOf(this.checkingForJobsInterval) < 0) {
            this.localInterval.cancel(this.checkingForJobsInterval);
            this.checkingForJobsInterval = null;
        }
    }
}

export class TemplateMachineEditComponent implements ng.IComponentOptions {
    public bindings = {
        machine: '<',
        showObjectId: '<'
    };
    public controller = TemplateMachineEditController;
    public controllerAs = '$TemplateMachineEditCtrl';
    public template = require('./machine-edit-template.html');
}
